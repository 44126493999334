.spinner-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .spinner-ball {
    width: 45px;
    height: 45px;
    background: #ff0183;
    border-radius: 50%;
    animation: bounce 350ms ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .spinner-text {
    padding-top: 60px;
    font-size: 16px;
  }

  @keyframes bounce {
    0% {
      transform: translate3d(0, 60px, 0);
    }
    100% {
      transform: translate3d(0, -10px, 0);
    }
  }
}
